.mainContainer {
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
}





.editCon{
    display: flex;
    justify-content: end;

    }
    
    .editItem {
        text-align: center;
        width: 120px;
        padding: 16px;
        border-radius: 5px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        border: none;
        background: white;
    }
    
    .not_allowed{
        cursor: not-allowed;
    }

.left{
    width : 250px;

}
.right{
    width : 80%;
    max-width: 1400px;

}


.mr{
    margin: 5px 0;
}


.profileInnerContainer {
    position: relative;
    width: fit-content;
}


.profileImg {
    border-radius: 50%;
    width: 110px;
    height: 110px;
}

.camera {
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;
    padding: 10px;
    border-radius: 50%;
}

.BtnContainer{
    width: 75%;
    display: flex;
    align-items: center;
    /* border: 1px solid #216E91; */
    margin-top: 10px;
    border-radius: 5px;
    padding-left: 5px;
    cursor: pointer;
}

.iconImgCon{
    margin-right: 10px;
}

.images{
    width: 20px;
}


.activeButton{
    background-color: #216E91;
    color: white;
    font-weight: bold;
}


.cancelandSubmit{
    display: flex;
    /* justify-content: end; */
    gap:20px;
    margin-top: 20px;
    padding-left: 21px;
  }
  
  
  .cancelCon{
  
  }
  .cancelBtn{
    background-color: white;
    border : 1px solid #216E91;
    padding: 10px 25px;
    border-radius: 5px;
    color: #216E91;
  }
  
  .btn_style{
    padding: 10px 25px;
    border : none;
    background-color: #216E91;
    color: white;
    border-radius: 5px;
  }
  