.container {
 
}

.filterBox {
display: flex;
flex-direction: row;
align-items: center;
gap: 10px;
/* justify-content: space-between; */
margin-bottom: 10px;
}

.tableContainer {
width: 100%;
}
