.mainContainer{

}

.dateContainer{
    display: flex;
 gap: 40px
}

.progressContainer{
display: flex;
width : 80%;
justify-content: space-between;
margin-top: 20px;
}

.infoCon{
  margin-bottom: 10px;
}
.infoCon >p{
margin: 0px;
}

.text{
font-weight: 500;  
font-size: 20px;
}
.nos{
  text-align: right;
  font-size: 26px;
  padding-right: 26px;
  font-weight: 600;
}

.reactCalender {
    width: 100% !important;
    border: none !important;
  }

  .present_date {
    background-color: #1ae11a61;
    color: white;  
    text-align: center;
    width: 42%;
    height: 95%;
    z-index: 0;
    border-radius: 50%;
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  
  .absent_date {
    background-color: #e1311a61;
    color: white;
    text-align: center;
    width: 42%;
    height: 95%;
    z-index: 0;
    border-radius: 50%;
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }


  .hide_selected_color .react-calendar__tile--active {
    background-color: transparent;
  }

  .react-calendar button {
    position: relative !important;
  }

  .react-calendar__tile .active{
    background-color: white !important;
  }