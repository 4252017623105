.topBar {
    /* border: 1px solid blue; */
    height: auto;
  }
  .topBarMain {
    width: 100%;
    padding: 5px;
    background: linear-gradient(90deg, #303870 0%, #2a1b4e 97.03%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    /* border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; */
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    /* margin-top: 20px; */
  }
  
  .card {
    width: 25%;
    
    padding: 5px;
    display: flex;
    flex-direction: column;
    
    justify-content: center;
    align-items: center;
  }
  
  .orangeBold {
    font-size: 50px;
    font-weight: bold;
    color: #216E91;
  }
  
  .white20{
      font-size: 20px;
      color: white;
  }