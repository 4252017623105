.mainData{
    margin: 20px 0;
}

.drawerContain{
    padding: 20px;
}

.textData{
    margin: 10px;
}