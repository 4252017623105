.mainSpecial{
    display: flex;
    flex-wrap: wrap;
    margin-top: 100px;
}

.mainBox{
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 22px 20px;
    background: #FFFFFF;
    box-shadow: 1px 1px 7px 5px rgb(0 0 0 / 10%), -4px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 5px;
    /* width: 29%; */
}


.image{
    width: 50px;
}

.text{
    color: #717070;
    font-size: 18px;
}