.container {
  padding: 30px;
  position: relative;
}

.topSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.middleSection {
  /* border: 1px solid black; */
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.errorText {
  width: 50%;
  position: relative;
  z-index: 5;
}
