
.boxShadow{
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 100%;
    width:100%;
}

.header {
    position:absolute;
    margin-top:-16px;
    margin-left:10px;
    background:white;
    padding:2px 5px;
    border:20px;
    line-height: 24px;
    color:rgb(131, 122, 122);
}

.content{
    padding:20px
}
