.mainBox{
    padding: 20px;
    box-shadow: 1px 1px 9px #a3a3a3;
    border-radius: 12px;
    background: #ffffffcc;
}

.colorData{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #efefef;
    border-radius: 12px;
    margin-bottom: 10px;
    font-size: 19px;
    font-weight: 600;
}