.attendanceHeader{
    font-size: 35px;
    font-weight: 500;
    line-height: 52.88px;
    margin-top: 0px;
}

.aContentTopC{
    display: flex;
    margin-bottom: 30px;
}

.boxShadow{
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 100%;
    width:100%;
}

.header {
    position:absolute;
    margin-top:-16px;
    margin-left:10px;
    background:white;
    padding:2px 5px;
    border:20px;
    line-height: 24px;
    color:rgb(131, 122, 122)
  }

.aTopL{
    width: 52%;
    margin-right: 3%;
}

.aTopR{
    width:47%;
}

.dougnutFlexC{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.dougnutCountC{
    width:25%;
}

.content{
    padding:20px
}

.circularProgC{
    display: flex;
    justify-items: center;
    align-items: center;
}

.customCir{
    width:40%;
    margin-right: 20px;
}

.cirPer{
    font-size: 25px;
    font-weight: 900;
}